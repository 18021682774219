import { FLEET_TYPES } from '@/constants/fleetTypes.const';
import { getValue } from '@emobg/web-utils';

export const GETTERS = {
  getCompanyData: 'getCompanyData',
  userHasCompany: 'userHasCompany',
  isOpenOrDedicatedFleet: 'isOpenOrDedicatedFleet',
  companyUuid: 'companyUuid',
  companyAllowsAdditionalDriver: 'companyAllowsAdditionalDriver',
  hasDedicatedFleet: 'hasDedicatedFleet',
};

export default {
  [GETTERS.getCompanyData](state) {
    return state.data;
  },

  [GETTERS.isOpenOrDedicatedFleet](state) {
    return getValue(state.data, 'company_fleets.open', false)
      ? FLEET_TYPES.open
      : getValue(state.data, 'company_fleets.dedicated', false)
        ? FLEET_TYPES.dedicated
        : null;
  },

  [GETTERS.hasDedicatedFleet](state) {
    return getValue(state.data, 'company_fleets.dedicated', false);
  },

  [GETTERS.userHasCompany](state) {
    return state.data && state.data.getUUID();
  },

  [GETTERS.companyUuid](state) {
    return getValue(state, 'data.uuid', null);
  },

  [GETTERS.companyAllowsAdditionalDriver](state) {
    return getValue(state, 'data.isAdditionalDriverAllowed', false);
  },
};
