import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { isActivated } from '@/helpers/user/status/userStatusHelper';

export const GETTERS = {
  allowsPersonalUseOfOpen: 'allowsPersonalUseOfOpen',
  userData: 'getUserData',
  userGeolocation: 'getUserGeolocation',
  isActivatedUser: 'isActivatedUser',
  profilePermissions: 'getProfilePermissions',
  userCompany: 'userCompany',
  companyAllowsCarpooling: 'companyAllowsCarpooling',
  doesCompanyOwnsData: 'doesCompanyOwnsData',
  userCompanyUuid: 'userCompanyUuid',
  userUuid: 'userUuid',
  userLocale: 'userLocale',
  hasCompanyAccessToOpenFleet: 'hasCompanyAccessToOpenFleet',
  canEmployeeChangeBusinessPaymentMethod:
    'canEmployeeChangeBusinessPaymentMethod',
  parentCSOperatorUuid: 'parentCSOperatorUuid',
  dedicatedFleetCSOperatorUuid: 'dedicatedFleetCSOperatorUuid',
  hasDedicatedFleet: 'hasDedicatedFleet',
  userFullName: 'userFullName',
  trackingId: 'trackingId',
  userCompanyName: 'userCompanyName',
  userCountry: 'userCountry',
  userHasCompany: 'userHasCompany',
  companyPays: 'companyPays',
  canChangeBusinessProfilePaymentMethod:
    'canChangeBusinessProfilePaymentMethod',
  canChangeCostAllocation: 'canChangeCostAllocation',
  userStatus: 'userStatus',
};

export default {
  [GETTERS.userData](state) {
    return state.userData;
  },

  [GETTERS.userGeolocation](state) {
    return state.geolocation;
  },

  [GETTERS.isActivatedUser](state) {
    return isActivated(get(state, 'userData.status'));
  },

  [GETTERS.userStatus](state) {
    return get(state, 'userData.status');
  },

  [GETTERS.profilePermissions]: state => profileUuid => state.permissions[profileUuid] || [],

  [GETTERS.userCompany]: state => get(state, 'userData.company'),

  [GETTERS.userHasCompany]: state => !isEmpty(get(state, 'userData.company')),

  [GETTERS.userCompanyUuid]: state => get(state, 'userData.company.uuid'),

  [GETTERS.userCompanyName]: state => get(state, 'userData.company.commercial_name'),

  [GETTERS.doesCompanyOwnsData]: state => get(state, 'userData.company.owns_employee_data') || false,

  [GETTERS.hasCompanyAccessToOpenFleet]: state => get(state, 'userData.company.fleets.open') || false,

  [GETTERS.companyAllowsCarpooling]: state => get(state, 'userData.company.allows_carpooling'),

  [GETTERS.allowsPersonalUseOfOpen]: state => get(state, 'userData.company.allows_personal_use_open'),

  [GETTERS.userUuid]: state => get(state, 'userData.uuid'),

  [GETTERS.userLocale]: state => get(state, 'userData.locale'),

  [GETTERS.canEmployeeChangeBusinessPaymentMethod]: state => get(state, 'userData.permissions.change_user_business_payment_method'),

  [GETTERS.parentCSOperatorUuid]: state => get(state, 'userData.company.cs_operator'),

  [GETTERS.dedicatedFleetCSOperatorUuid]: state => get(state, 'userData.company.dedicated_fleet_cs_operator'),

  [GETTERS.hasDedicatedFleet]: (_, getters) => !isNil(getters.dedicatedFleetCSOperatorUuid),

  [GETTERS.userFullName]: state => `${get(state, 'userData.firstName')} ${get(state, 'userData.lastName')}`,

  [GETTERS.trackingId]: state => get(state, 'userData.trackingId'),

  [GETTERS.userCountry]: state => get(state, 'userData.country'),

  [GETTERS.companyPays]: state => get(state, 'userData.company.company_pays'),

  [GETTERS.canChangeBusinessProfilePaymentMethod]: state => get(state, 'userData.permissions.change_user_business_payment_method'),

  [GETTERS.canChangeCostAllocation]: state => get(state, 'userData.company.cost_allocation'),
};
