import axios from 'axios';
import { external } from '@emobg/web-api-client';
import { HTTP_STATUS_CODES } from '@emobg/web-utils';

import get from 'lodash/get';
import isString from 'lodash/isString';

import config from '@/config';
import { ApiHeaders, ParseUrlQueryString } from '@/vue/api/api.service';
import { useAuth } from '@/composable/Api/Auth/auth';

const host = config.data.phoenixApiUrl;

const returnEmptyResponseOrResponse = response => {
  const isResponseEmpty = response.status === HTTP_STATUS_CODES.noContent || isString(response.data);

  return isResponseEmpty
    ? { ...response, data: [] }
    : response;
};

const backoffice = {

  userCompany: {
    getUserDetail() {
      const { idpUuid } = useAuth();

      return external.userCompanyUserAccount.getUserDetailFromIdp(idpUuid);
    },

    createUserNotificationProfile(profileUUID, params) {
      return axios
        .post(`${host}/user-company/v1/user-profiles/${profileUUID}/notification-channels`, params, {
          headers: ApiHeaders({ auth: true }),
        })
        .then(response => response.data);
    },

    updateUserNotificationProfile(notificationUUID, params) {
      return axios
        .put(`${host}/user-company/v1/user-notification-channels/${notificationUUID}`, params, {
          headers: ApiHeaders({ auth: true }),
        })
        .then(response => response.data);
    },

    cancelUserAccount(userUuid) {
      return axios
        .put(`${host}/user-company/v2/users/${userUuid}/cancel-account`, {}, {
          headers: ApiHeaders({ auth: true }),
        });
    },
  },

  carsharing: {
    openVehicleDoor(bookingUUID) {
      return axios.get(`${host}/carsharing/v1/bookings/${bookingUUID}/vehicle-open`, {
        headers: ApiHeaders({ auth: true }),
      });
    },

    closeVehicleDoor(bookingUUID) {
      return axios.get(`${host}/carsharing/v1/bookings/${bookingUUID}/vehicle-close`, {
        headers: ApiHeaders({ auth: true }),
      });
    },

    setBooking(params) {
      return axios
        .post(`${host}/carsharing/v2_1/bookings`, params, {
          headers: ApiHeaders({ auth: true }),
        });
    },

    activeBookingsRequests: { payload: null, time: null, request: null },

    updateBooking: {
      updateBooking(bookingId, payload) {
        return axios
          .put(`${host}/carsharing/v3/bookings/${bookingId}`, payload, {
            headers: ApiHeaders({ auth: true }),
          })
          .then(response => response.data);
      },
    },
  },

  app: {
    countries: null,

    getCountries() {
      if (this.countries) {
        return this.countries;
      }

      return axios.get(`${host}/app/v1/countries`, {
        headers: ApiHeaders({ auth: false }),
      });
    },

    getAlgoliaSecuredApiKey(params) {
      const defaultParams = {
        index: null,
        filters: null,
        validations: {},
        user_uuid: '',
      };

      const parameters = Object.assign(defaultParams, params);

      return axios.post(`${host}/app/v2/search-api-key`,
        {
          ...parameters,
        },
        {
          headers: ApiHeaders({ auth: true }),
        });
    },

    documentValidationProviders: null,

    getDocumentValidationProviders() {
      if (!!this.documentValidationProviders === true) {
        return this.documentValidationProviders;
      }

      this.documentValidationProviders
        = axios.get(`${host}/user-company/v1/cs-operators/document-validation-providers`, {
          headers: ApiHeaders({ auth: true }),
        });

      return this.documentValidationProviders;
    },
  },

  payments: {
    addPaymentMethod(userUuid, profileUUID, params) {
      const request = { number: null,
        type: null, // can be 'iban', 'card'
        holder: null,
        user_profile_uuid: profileUUID,
        default: 1, // can be 1 or 0 to set default this payment method
        ...params };

      return axios
        .post(`${host}/payments/v1/users/${userUuid}/payment-methods`, request, {
          headers: ApiHeaders({ auth: true }),
        });
    },

    addCompanyPaymentMethod(companyUUID, userUuid, params) {
      const request = { user_uuid: userUuid,
        number: null,
        type: null, // can be 'iban', 'card'
        holder: null,
        default: 1, // can be 1 or 0 to set default this payment method
        ...params };

      return axios
        .post(`${host}/payments/v1/companies/${companyUUID}/payment-methods`, request, {
          headers: ApiHeaders({ auth: true }),
        });
    },

    getPaymentMethodList(userUuid, params) {
      return axios
        .get(`${host}/payments/v3/users/${userUuid}/payment-methods`, {
          params,
          headers: ApiHeaders({ auth: true }),
        })
        .then(response => returnEmptyResponseOrResponse(response));
    },

    linkPaymentMethod(profileUUID, params) {
      const payload = {
        ...params,
      };

      return axios
        .put(`${host}/payments/v3/users/${profileUUID}/link-payment-method`, payload, {
          headers: ApiHeaders({ auth: true }),
        });
    },

    /**
     * Delete payment method
     * @param userUuid
     * @param params Object { user_payment_method_uuid: paymentMethodUUID }
     * @returns {*}
     */
    deletePaymentMethod(userUuid, params) {
      return axios
        .put(`${host}/payments/v3/users/${userUuid}/remove-payment-method`, params, {
          headers: ApiHeaders({ auth: true }),
        });
    },

    listCompanyPaymentMethods(companyUUID) {
      return axios
        .get(`${host}/payments/v1/companies/${companyUUID}/payment-methods`, {
          headers: ApiHeaders({ auth: true }),
        })
        .then(response => returnEmptyResponseOrResponse(response));
    },

    setDefaultCompanyPaymentMethod(companyUUID, companyPaymentMethodUUID, userUuid) {
      const request = { user_uuid: userUuid };
      return axios.put(`${host}/payments/v1/companies/${companyUUID}/payment-methods/${companyPaymentMethodUUID}/default`, request, {
        headers: ApiHeaders({ auth: true }),
      });
    },

    removeCompanyPaymentMethod(companyUUID, companyPaymentMethodUUID, userUuid) {
      const request = { user_uuid: userUuid,
        company_payment_method_uuid: companyPaymentMethodUUID };

      return axios.put(`${host}/payments/v1/companies/${companyUUID}/remove-payment-method`, request, {
        headers: ApiHeaders({ auth: true }),
      });
    },

    secure: {
      addUserPaymentMethod(userUuid, profileUUID, params) {
        const request = { number: null,
          type: null, // can be 'iban', 'card'
          holder: null,
          user_profile_uuid: profileUUID,
          default: 1, // can be 1 or 0 to set default this payment method
          ...params };

        return axios
          .post(`${host}/payments/v3/users/${userUuid}/payment-methods`, request, {
            headers: ApiHeaders({ auth: true }),
          });
      },

      addCompanyPaymentMethod(companyUUID, userUuid, params) {
        const request = { user_uuid: userUuid,
          number: null,
          type: null, // can be 'iban', 'card'
          holder: null,
          default: 1, // can be 1 or 0 to set default this payment method
          ...params };

        return axios
          .post(`${host}/payments/v2/companies/${companyUUID}/payment-methods`, request, {
            headers: ApiHeaders({ auth: true }),
          });
      },
    },
  },

  pricing: {
    contractTariff(tariffUuid, params) {
      const parameters = {
        periodicity: get(params, 'periodicity', null),
        profile: params.profile,
      };

      return axios.post(`${host}/pricing/v2/tariffs/${tariffUuid}/users`, parameters, {
        headers: ApiHeaders({ auth: true }),
      });
    },

    contractBusinessTariff(tariffUUID, companyUUID, params) {
      const parameters = {
        periodicity: get(params, 'periodicity', null),
      };

      return axios.post(`${host}/pricing/v1/tariffs/${tariffUUID}/companies/${companyUUID}`, parameters, {
        headers: ApiHeaders({ auth: true }),
      });
    },

    cancelTariffSubscription(tariffUUID) {
      return axios.post(`${host}/pricing/v1/tariffs/${tariffUUID}/cancel-subscription`, {}, {
        headers: ApiHeaders({ auth: true }),
      });
    },

    cancelCompanyTariffSubscription(tariffUUID, companyUUID) {
      return axios.post(`${host}/pricing/v1/tariffs/${tariffUUID}/companies/${companyUUID}/cancel-subscription`, {}, {
        headers: ApiHeaders({ auth: true }),
      });
    },
  },
};

export default backoffice;

export const MyApiUrl = {
  payments: {
    confirmUserPaymentMethod: ({ userUuid = '', userPaymentMethodUuid = '', queryStringParams = {} }) => (
      `${config.data.serverUrl}/api/payments/v1/users/${userUuid}/payment-methods/${userPaymentMethodUuid}/confirm-three-d-secure?${ParseUrlQueryString(queryStringParams)}`
    ),

    confirmCompanyPaymentMethod: ({ companyUuid = '', companyPaymentMethodUuid = '', queryStringParams = {} }) => (
      `${config.data.serverUrl}/api/payments/v1/companies/${companyUuid}/payment-methods/${companyPaymentMethodUuid}/confirm-three-d-secure?${ParseUrlQueryString(queryStringParams)}`
    ),
  },
};
